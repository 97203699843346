.login-page {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-box {
  background-color: #171717;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  row-gap: 14px;
  box-shadow: 0px 0px 20px #00000099;
}

.login-input-box {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  align-items: center;
}

input.login-input {
  border: solid 2px black;
  padding: 8px 16px;
  padding-left: 24px;
  width: 240px;
  border-radius: 6px;
}

input.login-input:focus-visible {
  outline: none;
  border: solid 2px #ffd500;
}

.login-button {
  background-color: #171717;
  border: solid 2px white;
  color: white;
  height: 40px;
  width: 120px;
  border-radius: 8px;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: center;
  cursor: pointer;
}

.login-button:hover {
  background-color: white;
  border: solid 2px white;
  color: black;
}

button.login-button:focus {
  outline: none;
  border: solid 2px #ffd500;
}

.error-row p {
  color: #ffd500;
}
