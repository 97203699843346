html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f7f7;
}

.nav-link {
  color: #fff;
  cursor: pointer;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
a {
  color: #0c0b0b;
  margin: 0;
}
