@import url(https://fonts.googleapis.com/css2?family=Baloo+Bhai+2:wght@400;500;600;700;800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Sora:wght@100;200;500;600&display=swap);
html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f7f7;
}

.nav-link {
  color: #fff;
  cursor: pointer;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
a {
  color: #0c0b0b;
  margin: 0;
}

.login-page {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-box {
  background-color: #171717;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  grid-row-gap: 14px;
  row-gap: 14px;
  box-shadow: 0px 0px 20px #00000099;
}

.login-input-box {
  display: flex;
  flex-direction: column;
  grid-row-gap: 8px;
  row-gap: 8px;
  align-items: center;
}

input.login-input {
  border: solid 2px black;
  padding: 8px 16px;
  padding-left: 24px;
  width: 240px;
  border-radius: 6px;
}

input.login-input:focus-visible {
  outline: none;
  border: solid 2px #ffd500;
}

.login-button {
  background-color: #171717;
  border: solid 2px white;
  color: white;
  height: 40px;
  width: 120px;
  border-radius: 8px;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: center;
  cursor: pointer;
}

.login-button:hover {
  background-color: white;
  border: solid 2px white;
  color: black;
}

button.login-button:focus {
  outline: none;
  border: solid 2px #ffd500;
}

.error-row p {
  color: #ffd500;
}

.logo-small {
  padding-left: 10px;
  height: 3.4rem;
  pointer-events: none;
}

.sidebar {
  top: 18px;
  left: 24px;
  width: 80px;
  background-color: #0c0b0b;
  border-radius: 12px;
  padding: 4px 8px;
  position: fixed;
  height: 95%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0px 20px #00000099;
  animation: fadeInAnimation ease-in-out 0.6s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes fadeInAnimation {
  0% {
    left: -100px;
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.sidebar .nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
}

.sidebar .nav-link {
  margin: 0;
  padding: 18px 0px;
  color: white;
  font-size: 28px;
  transition: all 0.2s ease-in-out;
}

.sidebar .nav-link:hover {
  color: #ffd500;
  transform: scale(1.1);
}

.logo-sidebar {
  padding: 0;
  height: 2.8rem;
}

.navbar-brand {
  cursor: pointer;
}

#main-appplication {
  min-height: 80%;
  padding-top: 18px;
}

#main-appplication.main-side-opened {
  padding-right: 24px;
  padding-left: 160px;
  height: auto;
}

.navbar-icon {
  font-size: 2rem;
}

.navbar {
  height: 4rem;
}

.new-menu-card {
  padding: 12px 24px;
  background-color: #eee;
  border-radius: 12px;
}

.wss-status {
  position: fixed;
  left: 8px;
  top: 8px;
  height: 12px;
  width: 12px;
  background-color: aqua;
  border-radius: 10px;
}

.wss-status.wss-status-connected {
  background-color: green;
}

.wss-status.wss-status-disconnected {
  background-color: red;
}

.wss-status.wss-status-connecting {
  background-color: yellow;
}

.column-wrap {
  display: flex;
  flex-direction: row;
  margin-top: -20px;
}

.column-wrap:first-child {
  margin-top: 0px;
}

.column-wrap .column {
  width: 50%;
}

.column-wrap .column-100 {
  width: 100%;
  margin: 0;
  padding: 0;
}

.column-wrap .column-lg-50 {
  width: 50%;
}

.column-wrap .column-child-lg-50 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 0;
  padding: 0;
}

.column-wrap .column-child-lg-50 > div {
  width: 50%;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 1549px) {
  .column-wrap {
    flex-direction: column;
  }

  .column-wrap .column {
    width: 100%;
  }

  .column-wrap .column-lg-50 {
    width: 100%;
  }

  .column-wrap .column-child-lg-50 > div {
    width: 100%;
  }
}
.card {
  margin: 20px;
  padding: 24px 22px;
  background-color: #e9e9e9;
  border-radius: 12px;
  position: relative;
  height: 120px;
  font-family: 'Roboto';
}

.card h1 {
  margin-top: 28px;
  font-family: 'Baloo Bhai 2';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 36px;
}

.card h2 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
}

.card .image-fix-dx {
  position: absolute;
  bottom: 0;
  right: 10%;
}

.card.number-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  grid-column-gap: 18px;
  column-gap: 18px;
  height: 60px;
}

.card.number-card .number-card-num {
  font-size: 64px;
  font-weight: 800;
}

.card.number-card .number-card-name {
  font-size: 16px;
  font-weight: 400;
}

.card.order-status-card {
  grid-column-gap: 18px;
  column-gap: 18px;
  height: 60px;
  padding-left: 42px;
  padding-right: 42px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.card .card-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.card .card-row-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  grid-column-gap: 36px;
  column-gap: 36px;
}

.card .card-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
}

.card .card-subtitle {
  margin-top: 6px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
}

.card .card-subtitle2 {
  margin-top: 6px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

.card .card-button {
  margin-top: 6px;
}

.card-button {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  padding: 10px 24px;
  transition: all 0.2s ease-in-out;
  border: solid 1px black;
  border-radius: 6px;
  cursor: pointer;
}

.card-button-primary {
  border: solid 1px #0c0b0b;
  border-radius: 6px;
  color: white;
  background-color: #0c0b0b;
}

.card .card-button-secondary {
  border: solid 1px black;
  color: #0c0b0b;
  background-color: transparent;
  border-radius: 6px;
}

.card .card-button-secondary:hover {
  border: solid 1px #4d4d4d;
  border-radius: 6px;
  color: white;
  background-color: #0c0b0b;
  box-shadow: 0px 0px 10px #00000039;
}

.card .card-button-primary:hover {
  border: solid 1px #4d4d4d;
  color: #0c0b0b;
  background-color: transparent;
  border-radius: 6px;
  box-shadow: 0px 0px 10px #00000039;
}

.card-button-inverted {
  border: solid 1px rgb(255, 255, 255);
  color: #000000;
  background-color: white;
  border-radius: 6px;
  text-align: center;
}

.card-button-inverted:hover {
  border: solid 1px #c8c8c8;
  color: #ffffff;
  background-color: transparent;
  border-radius: 6px;
  box-shadow: 0px 0px 10px #e6e6e639;
}

.filters-add-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  margin-bottom: 20px;
}

.filters-add-col {
  display: flex;
  flex-direction: row;
  grid-column-gap: 18px;
  column-gap: 18px;
}

.filter-box input {
  height: 16px;
  width: 200px;
  padding: 4px 12px;
}

.filter-box input[type='date'] {
  height: 16px;
  width: 104px;
  padding: 4px 12px;
}

.filter-box select {
  height: 28px;
  width: 100px;
  padding: 0px 12px;
}

.edit-link {
  font-size: 24px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.edit-link:hover {
  transform: scale(1.12);
}

.col-w-140 {
  width: 140px;
}

.order-item-element {
  width: 100%;
  min-width: 160px;
  max-width: 280px;
}

.close-print-box {
  font-size: 42px;
  background-color: #000000;
  color: white;
  width: 52px;
  padding-top: 2px;
  align-content: center;
  text-align: center;
  vertical-align: middle;
  border-radius: 300px;
  position: fixed;
  top: 12px;
  right: 12px;
  cursor: pointer;
}

.send-print-box {
  font-size: 32px;
  background-color: #000000;
  color: white;
  width: 52px;
  padding-top: 8px;
  padding-bottom: 6px;
  align-content: center;
  text-align: center;
  vertical-align: middle;
  border-radius: 300px;
  position: fixed;
  top: 12px;
  right: 82px;
  cursor: pointer;
}

.comanda-print-container {
  width: 21cm;
  height: 36.7cm;
  padding: 20mm 35mm 20mm 35mm;
  border: solid 4px black;
}

#toprint {
  margin-top: 46px;
}

.orders-list-print-container {
  display: grid;
  grid-template-columns: 50% 50%;
  margin: 0 -100px;
}

.orders-list-print-container .card.order-status-card {
  grid-column-gap: 18px;
  grid-column-gap: 18px;
  column-gap: 18px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 8px;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 6px;
}
.orders-list-print-container .col-w-140 {
  width: 180px;
}

.orders-list-print-container .col-w100 {
  width: 100%;
}

.orders-list-print-container .order-item-element {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
}

.orders-list-print-container .card-subtitle {
  font-size: 16px;
}

.hour-title {
  font-size: 14px;
  font-weight: 300;
}

.print-orders-title {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 12px;
}

.order-item-count {
  width: 24px;
  display: inline-block;
  text-align: right;
}
.card-order-date {
  margin-top: 6px;
  font-size: 13px;
}

.preparation-section {
  position: relative;
  display: flex;
  flex-direction: row;
  grid-column-gap: 84px;
  column-gap: 84px;
  background-color: #0c0b0b;
  color: white;
  font-size: 22px;
  padding: 18px 36px;
  border-radius: 12px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.title-in-preparation {
  position: absolute;
  top: 6px;
  left: 28px;
  font-size: 10px;
  color: #888;
}

.comande-container {
  padding-top: 24px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-row-gap: 16px;
  row-gap: 16px;
  grid-column-gap: 16px;
  column-gap: 16px;
}

.comanda-box {
  color: white;
  background-color: #0c0b0b;
  padding: 20px 24px;
  border-radius: 12px;
  width: 360px;
  box-shadow: 0px 0px 14px #2323234a;
}

.comanda-titlebar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0px;
  padding-bottom: 2px;
}

.comanda-title {
  font-weight: 600;
  font-size: 32px;
}

.comanda-hour {
  font-weight: 800;
  font-size: 32px;
}

.comanda-products {
  padding: 32px 0;
  padding-left: 32px;
  font-size: 20px;
}

.comanda-box.preparation {
  color: black;
  background-color: #ffd500;
  padding: 20px 24px;
  border-radius: 12px;
  width: 260px;
}

.comanda-box.ready .card-button-inverted,
.comanda-box.preparation .card-button-inverted {
  border: solid 1px black;
  color: white;
  background-color: black;
  border-radius: 6px;
  text-align: center;
  box-shadow: 0px 0px 8px #18181870;
}

.comanda-box.ready .card-button-inverted:hover,
.comanda-box.preparation .card-button-inverted:hover {
  border: solid 1px #ffffff;
  color: black;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0px 0px 10px #e6e6e65a;
}

/* .comanda-box.ready .card-button-inverted {
  border: solid 1px transparent;
  color: white;
  background-color: #00000055;
}

.comanda-box.ready .card-button-inverted:hover {
  border: solid 1px #ffffff;
  color: black;
  background-color: white;
} */

.comanda-box.ready {
  color: black;
  background-color: #00ff37;
  padding: 20px 24px;
  border-radius: 12px;
  width: 260px;
}

.nav-order {
  background-color: #000000;
  color: white;
  padding: 18px 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 8px #18181870;
  margin: 0 12px;
  min-height: 32px;
}

.card.instruction-card {
  height: 61px;
  margin-top: 32px;
}

.card.instruction-card .image-fix-dx {
  position: absolute;
  bottom: 0;
  height: 120px;
  right: 10px;
}

.card.instruction-card h1 {
  margin-top: -16px;
  font-size: 24px;
}

.card.instruction-card h2 {
  margin-top: 0;
  font-size: 14px;
  padding-right: 32%;
}

.card.order-category-card {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 24px 16px;
}

.card.order-category-card .card-button {
  margin-top: 24px;
  text-align: center;
}

.card.order-category-card .instruction-title {
  text-align: center;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 12px;
}

.card.order-category-card .opzione-container {
  border-radius: 12px;
  background-color: #ddd;
  padding: 12px 28px;
  padding-left: 42px;
  margin-bottom: 6px;
  position: relative;
  /* box-shadow: 0px 0px 4px #2c2c2c24; */
}

.card.order-category-card .opzione-container.selected {
  background-color: #b9b9b9;
}

.card.order-category-card .opzione-container .radio-status {
  position: absolute;
  top: 14px;
  left: 12px;
  width: 18px;
  height: 18px;
  border-radius: 30px;
  background-color: #eee;
  outline: solid 1px #e2e2e2;
  outline-offset: 2px;
}

.card.order-category-card .opzione-container .check-status {
  position: absolute;
  top: 14px;
  left: 12px;
  width: 18px;
  height: 18px;
  border-radius: 2px;
  background-color: #eee;
  outline: solid 1px #e2e2e2;
  outline-offset: 2px;
}

.card.order-category-card .opzione-container.selected .radio-status,
.card.order-category-card .opzione-container.selected .check-status {
  background-color: #555;
  outline-color: #777;
}

.card.order-category-card .opzione-container .nome-portata {
  font-size: 18px;
  font-weight: 500;
}

.card.order-category-card .opzione-container .descrizione-portata {
  margin-top: 2px;
  font-size: 13px;
  font-weight: 300;
}

.card.order-category-card .opzione-container .allergeni-portata {
  margin-top: 2px;
  font-size: 11px;
  font-weight: 300;
}

.card.order-category-card .opzione-container .features-portata {
  position: absolute;
  top: 12px;
  right: 18px;
}

.button-confirm-container {
  padding: 18px;
}

.nav-order {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.order-confirm {
  margin-top: 32px;
}

.header-add {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  align-content: center;
  padding: 22px;
  grid-column-gap: 12px;
  column-gap: 12px;
}

.new-menu-section {
  padding: 24px;
  background-color: #e9e9e9;
  border-radius: 16px;
}

.contenitore-sezioni-template .col-element {
  margin: 12px;
}

.button-add-option-menu {
  background-color: #000000;
  color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px 16px;
  border-radius: 4px;
  margin-top: 12px;
  margin-bottom: 12px;
}

.nome-title-sec {
  margin-right: 6px;
}

.toggle-input-row {
  margin-top: 12px;
  margin-bottom: 12px;
}

.allergeni {
  margin-top: 16px;
  margin-bottom: 16px;
}

.allergeni-title {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 8px;
}

.options-menu-allerg-section {
  background-color: #ccc;
  padding: 6px 12px;
  border-radius: 4px;
  margin: 4px;
}

.options-menu-allerg-section-remove {
  background-color: black;
  color: white;
  font-size: 13px;
  padding: 2px 5px;
  border-radius: 40px;
  cursor: pointer;
}

.option-single-admin {
  background-color: #ddd;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 12px;
  border-radius: 6px;
  margin-bottom: 12px;
}

.options-menu-section-remove {
  background-color: black;
  color: white;
  font-size: 13px;
  padding: 2px 5px;
  border-radius: 40px;
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 12px;
}

.pos-relative {
  position: relative;
}

.nome-ordine-input-row {
  margin-bottom: 16px;
}

.nome-ordine-input-row input {
  padding: 4px 12px;
}

.instructions {
  padding-bottom: 16px;
}

.card .column-gap-12 {
  grid-column-gap: 12px;
  column-gap: 12px;
}

.ud-arrow {
  padding: 2px 8px;
  background-color: white;
  margin: 8px;
  cursor: pointer;
  border-radius: 4px;
}

.up-down-counters {
  position: absolute;
  top: 10px;
  right: 4px;
}

.card.order-category-card .opzione-container.padding-top-boost {
  padding-top: 10px;
  padding-bottom: 16px;
  padding-left: 14px;
}

.card.order-category-card
  .opzione-container.padding-top-boost
  .features-portata {
  position: absolute;
  bottom: 12px;
  right: 18px;
  top: auto;
}

.restore-option {
  color: white;
  font-size: 13px;
  cursor: pointer;
}

h1.login-title {
  color: white;
  font-size: 1.4rem;
}

.reload-container {
  padding: 32px 20px;
}

.reload-container .card-button {
  text-align: center;
  padding: 16px;
  font-size: 1.1rem;
  cursor: pointer;
}

.item-prod-quantity-confirm {
  width: 18px;
  display: inline-block;
}

.order-confirm {
  position: relative;
}

.order-confirm .name-box {
  position: absolute;
  top: 8px;
  left: 28px;
}

.order-confirm .customer-box {
  text-align: center;
  margin-bottom: 12px;
}

.order-confirm .time-box {
  position: absolute;
  top: 8px;
  right: 28px;
}

.order-confirm .time-box1 {
  position: absolute;
  bottom: 8px;
  left: 28px;
}

.order-confirm .time-box2 {
  position: absolute;
  bottom: 8px;
  right: 28px;
}

.order-confirm .items-box {
  background-color: #ddd;
  padding: 12px 24px;
  border-radius: 12px;
  margin-top: 12px;
  margin-bottom: 28px;
}

.resizable-card {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.reload-container .card-button {
  margin-bottom: 12px;
}

.menu-inline-selection {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.menu-inline-selection .section-title-span {
  margin-right: 12px;
}

.menu-inline-selection .section-select-span {
  min-width: 260px;
}
.menu-inline-selection .section-select-span-small {
  min-width: 120px;
}

.schedule-day-box {
  background-color: #ddd;
  position: relative;
  padding: 10px 28px;
  margin: 8px 0;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-evenly;
}

.add-new-day-schedule {
  margin: 12px 0px;
  padding: 10px 22px;
  background-color: #111;
  color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 6px;
  cursor: pointer;
}

.actual-orders-delimiter {
  margin: 12px 20px;
  font-weight: 500;
}

.time-box.delete-box {
  cursor: pointer;
  background-color: #111;
  color: white;
  padding: 2px 8px;
  border-radius: 8px;
}

.card-button-disabled {
  background-color: #888;
  border: #999;
}

.nome-ordine-input-row {
  padding: 4px 6px;
}

.instruction-delivery-time {
  font-size: 13px;
  padding-top: 4px;
}

